import { Injectable } from '@angular/core';
import { Api } from "../provider/api.service";
import { Observable, throwError } from "rxjs";
import { an } from "@fullcalendar/core/internal-common";
import { HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Carrello } from "../../demo/components/pages/evento/dialog-edit/dialog-edit.component";

@Injectable({
    providedIn: 'root'
})
export class ListsService extends Api {

    /*
    *  Circuiti
    */

    getAllGift(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cart/allGift/');
    }

    // getFromOrderNumber(params: number): Observable<any> {
    //     return this.http.get(this.getBaseUrl() + 'cart/allGift?numeroOrdine=' + params);
    // }

    getFromOrderNumber(params: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cart/allGift?id=' + params);
    }


    resendGift(id: number) {
        return this.http.post(this.getBaseUrl() + 'cart/resend/' + id, {});
    }



    getOrderCestinati(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'ordini/list-cestinati');
    }










    patchGift(id: number, params: Partial<any>): Observable<any> {
        const url = `${this.getBaseUrl()}cart/updateGift/${id}`;
        console.log('Sending PATCH request to:', url, 'with data:', params);
        return this.http.patch(url, params);
    }


    getCircuito(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'circuiti/detail/' + id);
    }

    getCircuitiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'circuiti/list');
    }

    getCircuitiFasceList(params?: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'fasce/circuiti/list', { params });
    }

    deleteCircuito(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'circuiti/delete/' + id);
    }

    updateCircuito(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'circuiti/update/' + id, params);
    }

    addCircuito(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'circuiti/add', params);
    }

    getEventi(params?: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'store/eventi-list', { params });
    }


    /*
    *  Veicoli
    */

    getVeicoliList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'veicoli/list');
    }

    getVeicolo(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'veicoli/detail/' + id);
    }

    deleteVeicolo(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'veicoli/delete/' + id);
    }

    updateVeicolo(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'veicoli/update/' + id, params);
    }

    addVeicolo(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'veicoli/add', params);
    }

    /*
    *  Influencer
    */

    getInfluencerList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'influencer/list');
    }

    getInfluencer(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'influencer/detail/' + id);
    }

    deleteInfluencer(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'influencer/delete/' + id);
    }

    updateInfluencer(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'influencer/update/' + id, params);
    }

    addInfluencer(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'influencer/add', params);
    }

    createInfluencer(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'social/create-influencer', params);
    }

    updateInfluencers(id: number, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'social/update-influencer/' + id, params);
    }

    getListSocialByInfluencer(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/list-social/' + id);
    }

    getListContenutiByInfluencer(id: any, params: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/list-contenuti/' + id, { params });
    }

    getListContenutiBySocial(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/list-contenuti-social/' + id);
    }

    getLisDurata(id: number, params: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/list-durata/' + id, { params });
    }

    getStimaReach(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'valutazione/stima/reach', params);
    }

    getCPM(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'valutazione/cpm', params);
    }

    getSettoreList() {
        return this.http.get(this.getBaseUrl() + 'valutazione/settore/list')
    }

    createProposta(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'social/accordo/add', params);
    }


    /*
    *  Social
    */

    createSocial(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'social/add', params);
    }

    getSocialList() {
        return this.http.get(this.getBaseUrl() + 'social/list')
    }

    getSocial(id: any) {
        return this.http.get(this.getBaseUrl() + 'social/detail/' + id)
    }

    deleteSocial(id: any) {
        return this.http.delete(this.getBaseUrl() + 'social/delete/' + id)
    }

    updateSocial(id: number, params: any) {
        return this.http.patch(this.getBaseUrl() + 'social/update/' + id, params)
    }


    /*
    *  Contenuti
    */

    getContenutiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/contenuti/list');
    }

    getContenuto(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/contenuti/detail/' + id);
    }

    deleteContenuto(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'social/contenuti/delete/' + id);
    }

    updateContenuto(id: number, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'social/contenuti/update/' + id, params);
    }

    createContenuto(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'social/contenuti/add', params);
    }


    /*
    * Accordi
    */


    getAccordiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'accordi/list');
    }

    getAccordiListByInfluencer(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'accordi/list-by-influencer/' + id);
    }

    getAccordo(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'accordi/detail/' + id);
    }

    deleteAccordo(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'accordi/delete/' + id);
    }

    updateAccordo(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'accordi/update/' + id, params);
    }

    createAccordo(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'accordi/add', params);
    }


    /*
    *  Campagne
    */

    getCampagneList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'campagne/list');
    }

    getCampagna(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'campagne/detail/' + id);
    }

    deleteCampagna(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'campagne/delete/' + id);
    }

    updateCampagna(id: number, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'campagne/update/' + id, params);
    }

    getTipoCampagnaList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'campagne/tipo/list');
    }


    /*
    * Parametri Argomenti
    */

    getParametriArgomentiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/argomento/list');
    }

    getParametroArgomento(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/argomento/detail/' + id);
    }

    deleteParametroArgomento(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'calcolatore/argomento/delete/' + id);
    }

    updateParametroArgomento(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'calcolatore/argomento/update/' + id, params);
    }

    createParametroArgomento(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'calcolatore/argomento/add', params);
    }


    /*
    *  Argomenti
    */

    getArgomentiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/argomenti/list');
    }

    getArgomento(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'social/argomenti/detail/' + id);
    }

    deleteArgomento(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'social/argomenti/delete/' + id);
    }

    updateArgomento(id: number, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'social/argomenti/update/' + id, params);
    }

    createArgomento(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'social/argomenti/add', params);
    }

    /*
    *  Audience
    */

    getParametriAudienceList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/audience/list');
    }

    getParametroAudience(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/audience/detail/' + id);
    }

    deleteParametroAudience(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'calcolatore/audience/delete/' + id);
    }

    updateParametroAudience(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'calcolatore/audience/update/' + id, params);
    }

    createParametroAudience(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'calcolatore/audience/add', params);
    }

    /*
    *  Engagement Rate
    */

    getParametriErList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/er/list');
    }

    getParametroEr(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/er/detail/' + id);
    }

    deleteParametroEr(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'calcolatore/er/delete/' + id);
    }

    updateParametroEr(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'calcolatore/er/update/' + id, params);
    }

    createParametroEr(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'calcolatore/er/add', params);
    }

    /*
    *  Parametri
    */

    getParametriList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/parametri/list');
    }

    updateParametro(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'calcolatore/parametri/update/' + id, params);
    }

    /*
    *  Followers
    */

    getParametriFollowersList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/followers/list');
    }

    getParametroFollowers(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/followers/detail/' + id);
    }

    deleteParametroFollowers(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'calcolatore/followers/delete/' + id);
    }

    updateParametroFollowers(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'calcolatore/followers/update/' + id, params);
    }

    createParametroFollowers(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'calcolatore/followers/add', params);
    }

    /*
    *  Durata
    */

    getParametriDurataList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/durata/list');
    }

    getParametroDurata(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'calcolatore/durata/detail/' + id);
    }

    deleteParametroDurata(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'calcolatore/durata/delete/' + id);
    }

    updateParametroDurata(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'calcolatore/durata/update/' + id, params);
    }

    createParametroDurata(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'calcolatore/durata/add', params);
    }


    /*
    *  Regione
    */

    getRegioniList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'utility/regione/list');
    }

    getRegione(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'utility/regione/find/' + id);
    }

    createRegione(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'utility/regione/add', params);
    }


    /*
    *  Ruoli
    */

    getRuoliList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'ruoli/list');
    }

    getRuolo(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'ruoli/find/' + id);
    }

    createRuolo(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'ruoli/add', params);
    }

    updateRuolo(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'utenti/ruoli/update/' + id, params);
    }

    deleteRuolo(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'ruoli/delete/' + id);
    }

    /*
    *  Utenti
    */

    getUtentiList(id: number | undefined): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'utenti/list/' + id);
    }

    getUtente(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'utenti/find/' + id);
    }

    createUtente(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'utenti/add', params);
    }

    updateUtente(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'utenti/update/' + id, params);
    }

    deleteUtente(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'utenti/delete/' + id);
    }

    /*
    *  Permessi
    */

    getPermessiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'permission/list');
    }

    getPermessiId(params: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'permission/detail', { params });
    }

    savePermessi(params: any) {
        return this.http.post(this.getBaseUrl() + 'permission/add', params);
    }

    /*
    * Agenzia
    */

    getAgenzieList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'agenzia/list');
    }

    getAgenzia(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'agenzia/detail/' + id);
    }

    createAgenzia(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'agenzia/add', params);
    }

    updateAgenzia(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'agenzia/update/' + id, params);
    }

    deleteAgenzia(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'agenzia/delete/' + id);
    }

    /*
    * Tipologia Campagna
    */

    getTipologiaCampagnaList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'campagne/tipo/list');
    }

    getTipologiaCampagna(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'campagne/detial/' + id);
    }

    createTipologiaCampagna(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'campagne/add', params);
    }

    updateTipologiaCampagna(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'campagne/update/' + id, params);
    }

    deleteTipologiaCampagna(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'campagne/delete/' + id);
    }


    getTimeLineList(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'timeline/view/' + id);
    }

    getTimeLineOrarioList(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'timeline/view-time/' + id);
    }


    /*
    *  Fasce
    */

    getFasceList(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'fasce/list/' + id);
    }

    getFascia(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'fasce/detail/' + id);
    }

    createFascia(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'fasce/add', params);
    }

    updateFascia(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'fasce/update/' + id, params);
    }

    deleteFascia(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'fasce/delete/' + id);
    }


    /*
    *  Cofanetti
    */

    getCofanettiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cofanetti/cofanetto/list');
    }

    getCofanetto(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cofanetti/detail/' + id);
    }

    createCofanetto(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'cofanetti/add', params);
    }

    updateCofanetto(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'cofanetti/update/' + id, params);
    }

    deleteCofanetto(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'cofanetti/delete/' + id);
    }

    getSerialiList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cofanetti/seriali/list');
    }

    getSerialiListCofanetto(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cofanetti/seriali/list/cofanetto');
    }

    getSerialiLiberi(params?: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'spedizione/seriali-liberi', { params });
    }

    getSeriali(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'cofanetti/seriali/detail/' + id);
    }

    createSeriali(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'cofanetti/seriali/create', params);
    }

    updateSeriali(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'cofanetti/seriali/update/' + id, params);
    }

    deleteSeriali(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'cofanetti/seriali/delete/' + id);
    }

    /*
    *  Ordini
    */

    getOrdiniList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'ordini/list');
    }






    getAmministrazioneList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'ordini/list-amministrazione');
    }


    //TARGET ------ ------ ------
    getOrdini(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'ordini/detail/' + id);
    }

    createOrdini(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'ordini/add', params);
    }

    updateOrdini(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'ordini/update/' + id, params);
    }




    //delete ordini
    deleteOrdini(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'ordini/delete/' + id);
    }


    cestinaOrdine(id: any, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'ordini/cestinato/' + id, params);
    }

    cestinaOrdineR(id: any, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'ordini/cestinatoR/' + id, params);
    }





    getClientiList(params: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'anagrafiche/list', { params });
    }

    getCliente(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'anagrafiche/detail/' + id);
    }

    createCliente(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'anagrafiche/add', params);
    }

    updateCliente(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'anagrafiche/update/' + id, params);
    }

    deleteCliente(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'anagrafiche/delete/' + id);
    }

    getLocation(params: any): Observable<any> {
        return this.http.get<any>(this.getBaseUrl() + 'utility/location/', { params });
    }

    getStati(): Observable<any> {
        return this.http.get<any>(this.getBaseUrl() + `utility/stato/list`);
    }


    /*
    *  Analytics
    */

    getAnalyticsList(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'timeline/analytics', { params: { idEvento: id } });
    }

    getComunicazioniList(params: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'timeline/comunicazioni-list', { params });
    }

    createComunicazioni(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'timeline/comunicazioni-add', params);
    }

    updateComunicazioni(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'timeline/comunicazioni-update/' + id, params);
    }


    /*
    *  Utility
    */

    // Lista stati progresso
    getStatiProgresso(params: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'utility/stati-progresso/list', { params });
    }

    createStatiProgresso(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'utility/stati-progresso/add', params);
    }

    updateStatiProgresso(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'utility/stati-progresso/update/' + id, params);
    }

    deleteStatiProgresso(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'utility/stati-progresso/delete/' + id);
    }


    getServiziList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'servizi/list');
    }

    deleteServizi(id: number | undefined): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'servizi/delete/' + id);
    }

    getServizio(id: number | undefined): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'servizi/detail/' + id);
    }

    createServizio(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'servizi/add', params);
    }


    //Target 2 --- ----- ----- ----- ----- ----- -----
    getOrdineDetail(id: number | undefined) {
        return this.http.get(this.getBaseUrl() + 'ordini/detail/' + id);
    }

    patchNoteOrdini(id: number, params: any) {
        return this.http.patch(this.getBaseUrl() + 'ordini/note-order/' + id, params);
    }






    deleteEvento(idEvento: number | undefined, params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'eventi/delete/' + idEvento, params);
    }

    generaTimeLine(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'eventi/genera-timeline/' + id);
    }

    generaLDV(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'spedizione/crea-ldv', params);
    }

    downloadCsv(params: { tipo: string }): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'transcodifiche/download-csv', { params, responseType: 'blob' });
    }


    sendEmailWhatsappConfermaOrdine(params: any) {
        return this.http.post(this.getBaseUrl() + 'cart/send-email-whatsapp-conferma-ordine', params);
    }

    sendEmailInvioCodici(params: any) {
        return this.http.post(this.getBaseUrl() + 'cart/end-email-codici-cofanetti-digitale', { params });
    }

    getCouponList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'coupon/list');
    }

    getCoupon(id: number | undefined): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'coupon/detail/' + id);
    }

    createCoupon(params: any) {
        return this.http.post(this.getBaseUrl() + 'coupon/add-coupon/', params);
    }

    updateCoupon(id: number | undefined, params: any) {
        return this.http.patch(this.getBaseUrl() + 'coupon/update/' + id, params);
    }

    deleteCoupon(id: number | undefined) {
        return this.http.delete(this.getBaseUrl() + 'coupon/delete/' + id);
    }

    getSpedizioniList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'spedizione/list');
    }

    getListini(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'fasce/listini');
    }

    exportOrder(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'transcodifiche/export-ordini', params, { responseType: 'blob' });
    }

    addOrderNote(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + 'ordini/add-nota', params);

    }

    editOrdine(params: any, id: number | undefined) {
        return this.http.patch(this.getBaseUrl() + `ordini/edit-info/${id}`, params);
    }

    getStatiRegioneProvincia() {
        return this.http.get(this.getBaseUrl() + `store/stati-regioni-province`);
    }

    addSerialeToOrder(params: { idDettaglio: number | undefined; idCofanetto: any }) {
        return this.http.put(this.getBaseUrl() + `ordini/add-seriale-to-order`, params);
    }

    importSeriali(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        const uploadReq = new HttpRequest('POST', this.getBaseUrl() + `transcodifiche/cofanetti-v4`, formData, {
            reportProgress: true, // Abilita il monitoraggio del progresso
        });

        return this.http.request(uploadReq).pipe(
            map(event => this.getProgressAndResponse(event))
        )
    }

    private getProgressAndResponse(event: any): { progress: number; response: any } {
        if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((100 * event.loaded) / event.total);
            return { progress, response: null };
        } else if (event instanceof HttpResponse) {
            return { progress: 100, response: event.body };
        }
        return { progress: 0, response: null };
    }

    getCorrieri(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `spedizione/costo-ldv`, params);
    }

    stampaEtichette(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `spedizione/stampa-ldv`, params);
    }

    stampaLdDV(selectedProducts: any[]) {
        return this.http.post(this.getBaseUrl() + `spedizione/stampa-ldv`, selectedProducts, { responseType: 'blob' });
    }

    getStoriaOrdine(idOrdine: any) {
        return this.http.get(this.getBaseUrl() + `ordini/movimenti-ordine/${idOrdine}`,);
    }

    getEsperienzeList() {
        return this.http.get(this.getBaseUrl() + `ordini/esperienze-attivazioni-list`);
    }

    getServiziEsperienzeList(params: any) {
        return this.http.get(this.getBaseUrl() + `ordini/esperienze-attivazioni-servizi`, { params });
    }

    updateStatoOrdine(params: any, idOrdine: any) {
        return this.http.put(this.getBaseUrl() + `ordini/update-stato-pagamento/${idOrdine}`, params);
    }

    importFruiti(file: File, id: number) {
        const formData = new FormData();
        formData.append('file', file);
        const uploadReq = new HttpRequest('POST', this.getBaseUrl() + `transcodifiche/import-fruiti/${id}`, formData, {
            reportProgress: true, // Abilita il monitoraggio del progresso
        });

        return this.http.request(uploadReq).pipe(
            map(event => this.getProgressAndResponse(event))
        )
    }

    uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        const uploadReq = new HttpRequest('POST', this.getBaseUrl() + `media/upload-file/`, formData, {
            reportProgress: true, // Abilita il monitoraggio del progresso
        });

        return this.http.request(uploadReq).pipe(
            map(event => this.getProgressAndResponse(event))
        )
    }

    uploadFileV2(file: any, folderName: string = '') {
        const formData = new FormData();
        formData.append('files', file[0]);
        formData.append('folderName', folderName); // Aggiungi il nome della cartella

        const uploadReq = new HttpRequest('POST', this.getBaseUrl() + `media/upload-file-v2/`, formData, {
            reportProgress: true, // Abilita il monitoraggio del progresso
        });

        return this.http.request(uploadReq).pipe(
            map(event => this.getProgressAndResponse(event))
        )
    }

    uploadContabile(file: File, idOrdine: number | undefined, tipo: string, cartella: string) {
        const formData = new FormData();
        formData.append('file', file);
        const uploadReq = new HttpRequest('POST', this.getBaseUrl() + `ordini/add-allegato/${idOrdine}/${tipo}/${cartella}`, formData, {
            reportProgress: true, // Abilita il monitoraggio del progresso
        });

        return this.http.request(uploadReq).pipe(
            map(event => this.getProgressAndResponse(event))
        )
    }

    getOrdiniAssociazioni(params: { q: string }) {
        return this.http.get(this.getBaseUrl() + `ordini/associazioni`, { params });
    }

    associaOrdine(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `ordini/associa-ordine`, params);
    }

    getSpedizione(params: any) {
        return this.http.get(this.getBaseUrl() + `spedizione/get-tracking`, { params });
    }

    sendEmailWahtsappAttivazioneCofanetto(params: any) {
        return this.http.post(this.getBaseUrl() + `cart/email-attivazione-cofanetto`, params);
    }

    reSendMessage(params: { oggetto: any }) {
        return this.http.post(this.getBaseUrl() + `cart/re-send-message`, params);
    }

    getTokenOpenInBrowser(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + `anagrafiche/open-in-browser/${id}`);
    }

    cambiaPassword(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `utenti/update-password`, params);
    }

    getOrderPrefix(): Observable<any> {
        return this.http.get(this.getBaseUrl() + `ordini/prefissi-ordini`);

    }

    passaDataLibera(params: { idDettaglio: any }) {
        return this.http.post(this.getBaseUrl() + `ordini/passa-data-libera`, params);
    }

    getCofanettiGeneratiList() {
        return this.http.get(this.getBaseUrl() + `cofanetti/seriali/generati/list`);
    }

    generaCodici(params: any) {
        return this.http.post(this.getBaseUrl() + `cofanetti/seriali/genera`, params);
    }

    changePagamento(params: { pagato: any }, id: number | undefined) {
        return this.http.patch(this.getBaseUrl() + `ordini/change-pagamento/${id}`, params);
    }

    cambiaPasswordById(id: number, params: any): Observable<any> {
        return this.http.put(this.getBaseUrl() + `utenti/update-password/${id}`, params);
    }

    getZendeskUtenti() {
        // ritorna il josn in /Users/carloiodice/Documents/GitHub/wcr-angular/src/app/demo/components/pages/zendesk/list-circuiti/text.json

        /* return this.http.get<any>('/assets/demo/data/text.json').toPromise()
             .then(res => res.data as any[])
             .then(data => data);*/
        return this.http.get(`https://zendesk.wecanrace.it/api/zendesk/zendesk-list`);
    }

    getZendeskUtentiHistory(zendeskUserId: any) {
        return this.http.get(`https://zendesk.wecanrace.it/api/zendesk/zendesk-list-history/${zendeskUserId}`);
    }

    printCof(id: any) {
        return this.http.get(this.getBaseUrl() + `cofanetti/seriali/stampa-codici/${id}`);
    }

    deleteCofanettoGenerato(id: number) {
        return this.http.delete(this.getBaseUrl() + `cofanetti/seriali/generati/elimina/${id}`);
    }

    getOrderDetail(id: string | null | undefined): Observable<any> {
        return this.http.get(this.getBaseUrl() + `ordini/dettaglio-ordine/${id}`);
    }

    dissociaCofanetto(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `ordini/dissocia-ordine/`, params);
    }


    getEsperienzeTimeline(id: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + `timeline/esperienza/${id}`);
    }

    infoAddEsperienza(idEvento: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + `timeline/info-add-esperienza/${idEvento}`);
    }

    getTimelineTurni(idEvento: number, idVeicolo: number, numeroGiri: number): Observable<any> {
        return this.http.get(this.getBaseUrl() + `timeline/turni-list-esp/${idEvento}/${idVeicolo}/${numeroGiri}`);
    }

    updateEsperienzaInTrack(idEsperienza: number, carrello: Carrello) {
        return this.http.patch(this.getBaseUrl() + `timeline/update-esperienza/${idEsperienza}`, carrello);
    }

    modificaTurni(idEvento: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + `timeline/get-turni-veicoli-piloti/${idEvento}`);
    }

    updateTurno(product: any) {
        return this.http.patch(this.getBaseUrl() + `timeline/update-turno`, product);
    }

    aggiornaOrdiniGsheet() {
        return this.http.get(this.getBaseUrl() + `transcodifiche/get-order-gsheet`);
    }

    /*
    *  Cessioni
    */

    updateEmailDestinatario(id: number | undefined, params: any): Observable<any> {
        return this.http.patch(this.getBaseUrl() + 'cessioni/update/' + id, params);
    }

    deleteCessione(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'cessioni/delete/' + id);
    }

    /*
    *  Blog
    */

    getPostsList(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'blog/list');
    }

    getPostById(id: any): Observable<any> {
        const params = new HttpParams().set('id', id);
        return this.http.get(this.getBaseUrl() + 'blog/post', { params: params });
    }

    addPost(formData: any): Observable<any> {
        const headers = new HttpHeaders().set('enctype', 'multipart/form-data');
        return this.http.post(this.getBaseUrl() + 'blog/add', formData, { headers });
    }

    getAutoriList(id: number | undefined): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'blog/listAutori/' + id);
    }

    updatePost(id: number | undefined, params: any): Observable<any> {
        console.log(params);
        const headers = new HttpHeaders().set('enctype', 'multipart/form-data');
        return this.http.patch(this.getBaseUrl() + 'blog/update/' + id, params, { headers: headers });
    }

    deletePost(id: any): Observable<any> {
        return this.http.delete(this.getBaseUrl() + 'blog/delete/' + id);
    }

    getImage(name: string | undefined): Observable<Blob> {
        console.log('Name', name);
        return this.http.get(this.getBaseUrl() + 'blog/image/' + name, { responseType: 'blob' })
    }

    /*
    *  QrScanner
    */

    getSerialiAssociatiQr(): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'qr-scanner/list');
    }

    getValidationSeriale(seriale: any): Observable<any> {
        console.log('Seriale', seriale);
        return this.http.get(this.getBaseUrl() + 'qr-scanner/' + seriale);
    }

    getPrevisioni(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + 'timeline/previsione-veicoli/' + id)
    }

    getAutoEvento(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `timeline/auto-evento`, { params });
    }

    prezziCircuiti(idCircuto: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + `timeline/prezzi-auto/${idCircuto}`);
    }

    updateEsperienza(idEsperienza: number, params: any): Observable<any> {
        return this.http.put(this.getBaseUrl() + `ordini/update-esperienza/${idEsperienza}`, params);
    }

    changeFruito(idEsperienza: any, idFruito: number): Observable<any> {
        return this.http.put(this.getBaseUrl() + `ordini/change-fruito/${idEsperienza}/${idFruito}`, {});

    }

    getImagesCircuitoVeicoli(id: any): Observable<any> {
        return this.http.get(this.getBaseUrl() + `circuiti/image-car-list/${id}`);
    }

    saveImageCircuitiVeicoli(params: any): Observable<any> {
        return this.http.post(this.getBaseUrl() + `circuiti/save-image-car-list`, params);
    }

    deleteImageCircuitoVeicoli(id: number): Observable<any> {
        return this.http.delete(this.getBaseUrl() + `circuiti/delete-image-car-list/${id}`);
    }

    exportPrezzi(id: number | undefined): Observable<any> {
        return this.http.get(this.getBaseUrl() + `fasce/export-fasce/${id}`, {
            responseType: 'blob' // Important for binary data
        });
    }

    uploadFileToServer(file: any, id: number | undefined): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(this.getBaseUrl() + `fasce/import-prezzi/${id}`, formData);
    }

    getListaReport(): Observable<any> {
        return this.http.get(this.getBaseUrl() + `ordini/list-report`);
    }
}
