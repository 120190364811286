import {LOCALE_ID, NgModule} from '@angular/core';
import {
    AsyncPipe,
    CommonModule,
    HashLocationStrategy,
    LocationStrategy,
    NgClass,
    NgForOf,
    NgIf,
    registerLocaleData
} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppLayoutModule} from './layout/app.layout.module';
import {RequestInterceptor} from "./core/auth/request.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ListsService} from "./core/lists/lists.service";
import {ToastModule} from "primeng/toast";
import {ConfirmationService, MessageService} from "primeng/api";
import {GroupByPipe} from './shared/pipe/group-by.pipe';
import {DialogService} from "primeng/dynamicdialog";
import {ToolbarModule} from "primeng/toolbar";
import {ButtonModule} from "primeng/button";
import {SplitButtonModule} from "primeng/splitbutton";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {ImageModule} from "primeng/image";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ChatService} from "./demo/components/apps/chat/service/chat.service";
import localeIt from '@angular/common/locales/it';
import { FileSizePipe } from './shared/directive/file-size.pipe';

registerLocaleData(localeIt, 'it')


@NgModule({
    declarations: [
        AppComponent,
        GroupByPipe,
        FileSizePipe,
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        AppLayoutModule,
        ToastModule,
        NgForOf,
        ToolbarModule,
        ButtonModule,
        SplitButtonModule,
        NgIf,
        DialogModule,
        InputTextModule,
        ImageModule,
        ReactiveFormsModule,
        NgClass,
        FormsModule,
        AsyncPipe,
        ProgressSpinnerModule,
    ],
    providers: [
        DialogService,
        MessageService,
        ListsService,
        ChatService,
        ConfirmationService,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: "it-IT"
        }
    ],
    exports: [
        GroupByPipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
